import { defineStore } from 'pinia';

interface State {
  userInfo: UserInfo | null;
}

export const useUserStore = defineStore('user', {
  state: (): State => ({ userInfo: null }),
  actions: {
    setUserInfo(userInfo: UserInfo) {
      this.userInfo = userInfo;
    }
  }
});
