import dayjs from 'dayjs';

// fileName: 一级菜单名称_二级菜单_20020312年月日+五位不重复随机数
export const downLoad = (url: string, filePre: string = '') => {
  const fileName = `${filePre}_${dayjs().format('YYYYMMDD')}${Math.round(Math.random() * 100000)}`;
  const eleLink = document.createElement('a');
  eleLink.href = url;
  eleLink.download = fileName;
  eleLink.click();
};

// （暂存）根据后端加密方案，需要传入两个自定义header：Authorization、date
export const handleDownLoad = (obj: { date: string; sign: string; url: string }) => {
  const { url, date, sign } = obj;
  fetch(url, {
    method: 'GET',
    headers: new Headers({
      Authorization: sign,
      date
    })
  }).then((res) => {
    res.blob().then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      // 这里的文件名根据实际情况从响应头或者url里获取
      const filename = 'hong.jpg';
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    });
  });
};

export const isDateStr = (date: string) => /\d{4}-\d{2}-\d{2}/.test(date);

/**
 * 把字符用指定字符填充
 * @param h 待格式化内容
 * @param symbol 符号
 * @example
 *  const month = (new Date()).getMonth() // -> 8
 *  const monthFull = stringPad(month, '0') // -> '08'
 */
export const stringPad = (str: string | number, symbol: string = '0', len: number = 2) =>
  (symbol.repeat(len) + str).slice(-len);

/**
 * 判断是否跨年
 * @param range 日期字符串范围 ['2020-12xxx', '2021-12xxx']
 */
export const isYearCross = (range: string[]): boolean => {
  const a = range[0].match(/\d{4}/);
  const b = range[1].match(/\d{4}/);
  if (a !== null && b !== null) {
    return a[0] !== b[0];
  }
  return true;
};

/**
 * 为空则返回空，否则返回其它结果
 * @param a 字段值
 * @param b 待返回值
 * @example
 *  const a = a === '' ? '' : `${a}_test` // before
 *  const a = handleEmptyString(a, `${a}_test`)
 */
export const handleEmptyString = (a: string, b: any) => (a === '' ? '' : b);

/**
 * 千分位数字格式，小数点不格式化
 * https://mrluo.life/article/detail/115
 * @param num  待格式化数值
 * @param symbol 连接符
 * @example
 *  formatNumber('12345.1234') // 12,345.1234
 */
export const formatNumber = (num: string | number, symbol: string = ','): string => {
  if (num === '' || num === undefined || num === null) return '';
  const nums = num.toString().split('.');
  let intPart = nums[0];
  const digitPart = nums[1];
  let ret = '';
  while (intPart.length > 3) {
    ret = `${symbol}${intPart.slice(-3)}${ret}`;
    intPart = intPart.slice(0, intPart.length - 3);
  }
  if (intPart) {
    ret = intPart + ret;
  }

  return ret + (digitPart ? `.${digitPart}` : '');
};
/**
 * 上传文件前检查文件类型
 * @param file
 * @param accept string ".zip"
 */
export const checkFileExt = (file: File, accept: string): boolean => {
  // accept设置为.zip居然可以选择其它文件，所以通过文件后缀验证
  const fileName = file.name;
  const pos = fileName.lastIndexOf('.');
  const lastName = fileName.substring(pos, fileName.length);
  if (accept !== lastName) {
    return false;
  }
  return true;
};

/**
 * 下载 ${fileUrl} 对应的资源
 * 注意：在chrome下，如果资源不是同源的，download属性是无效的
 * @param fileName
 * @param fileUrl
 */
export const downloadLinkFile = (fileName: string, fileUrl: string) => {
  const link = document.createElement('a');
  const extensions = fileUrl.split('.').pop();

  fetch(fileUrl)
    .then((res) => res.blob())
    .then((blob) => {
      // 将链接地址字符内容转变成blob地址
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.${extensions}`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
    });
};
// eslint-disable-next-line import/no-mutable-exports
let projectName = '';

export const setProjectName = (pname: string = '') => {
  projectName = pname;
};

export { projectName };
