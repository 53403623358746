import { initGlobalState, MicroAppStateActions } from 'qiankun';

const initialState = {
  // 新建商品，页面搭建隐藏左侧菜单
  hideMenu: false,
  // 页面搭建，隐藏顶部栏
  hideTop: false,
  authResourceList: [],
  // 当前进入子应用的id
  projectId: -1
};
const actions: MicroAppStateActions = initGlobalState(initialState);

export default actions;
