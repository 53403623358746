import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized
} from 'vue-router';

import Home from '@/pages/home/index.vue';
import Login from '@/pages/login/index.vue';
import HandleLogin from '@/pages/handleLogin/index.vue';
import ErrorPage from '@/pages/ErrorPage/404.vue';
import PrivateImageLooker from '@/pages/common/privateImageLooker.vue';
import startQiankun, { setupQiankun } from '@/micro';
import { useUserStore, useSystemStore } from '@/store';
import { loginBUserDetail } from '@/api/user';
import getApps from '@/micro/apps';
import { RegistrableApp } from '@/micro/interfaces';

const NoRightsPage = () => import('@/pages/noRightsPage/index.vue');
const { title } = require('../../config');

export const constRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requireAuth: false,
      title: '登录'
    }
  },
  {
    path: '/sso/callback',
    name: 'handleLogin',
    component: HandleLogin,
    meta: {
      requireAuth: false,
      title: '登录成功'
    }
  },
  {
    path: '/privateImage',
    name: 'privateImage',
    component: PrivateImageLooker,
    meta: {
      requireAuth: false,
      title: '查看图片'
    }
  },
  {
    path: '/404',
    name: '404',
    component: ErrorPage,
    meta: {
      requireAuth: false,
      title: '404'
    }
  },
  {
    path: '/noRights',
    component: NoRightsPage,
    meta: {
      title: '暂无权限',
      hidden: true
    }
  }
];

export const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // redirect: '/app/order-management',
    meta: {
      requireAuth: true,
      title: '首页'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: constRoutes
});

// 将子应用注册，开启放到路由钩子里，否则先处理子应用，初始进来的路由信息无法获取
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    console.log(to.path);
    const userStore = useUserStore();
    const systemStore = useSystemStore();
    if (to.path === '/login' || to.path === '/noRights') {
      next();
    }
    if (!userStore.userInfo) {
      try {
        const res = await loginBUserDetail();
        userStore.setUserInfo(res.data.userInfoDTO);
      } catch (e) {
        next('/login');
      }
    }
    let apps: RegistrableApp[];
    console.log(_from, to);
    // 项目列表为空
    if (systemStore.apps.length === 0 && !systemStore.isSetApps) {
      console.log(systemStore.isSetApps);
      apps = await getApps();
      // 注册子应用。
      // 当微应用信息注册完之后，一旦浏览器的 url 发生变化，便会自动触发 qiankun 的匹配逻辑，
      // 所有 activeRule 规则匹配上的微应用就会被插入到指定的 container 中，同时依次调用微应用暴露出的生命周期钩子。
      systemStore.updateApps(apps as any);
      setupQiankun(apps);

      // @ts-ignore
      routes[0].redirect = apps[0]?.activeRule;
      routes.forEach((routeItem) => {
        router.addRoute(routeItem);
      });
    }
    apps = systemStore.apps;
    console.log('准备跳转到无权限路由');
    if (!Array.isArray(apps) || apps.length === 0) {
      next('/noRights');
    }
    console.log('没有跳转到无权限路由');

    if (to.meta.title) {
      document.title = title;
    }

    const isMatch = apps.some((app) => {
      const reg = new RegExp(`${app.activeRule}/?`);
      return reg.test(to.path);
    });
    // 任务中心-下载列表 不展示右下角下载icon
    systemStore.$patch((state) => {
      state.downloadIconShow =
        to.path.substring(to.path.length - 12, to.path.length) !== 'downloadList';
    });
    // addRoute 刚添加的redirect不会生效，需要手动粗发
    if (to.path === '/') {
      next({ ...to, replace: true });
    }
    // 是否匹配主应用路由
    if (to.name && to.meta.requireAuth === false) {
      next();
      // 若子应用路由匹配不上则返回 404
    } else if (!isMatch && apps.length) {
      next({ name: '404' });
    } else {
      startQiankun({
        prefetch: false,
        urlRerouteOnly: true
      });
      next();
    }
  }
);

export default router;
