import { RegistrableApp } from './interfaces';
import { getUserProjects } from '@/api/user';
import actions from '@/shared/actions';
import router from '@/router';
import { useUserStore, useSystemStore } from '@/store';

const { domainName, subAppPath } = require('../../config');

const env = process.env.NODE_ENV || '';
let localPorts: { [key: string]: number } = {
  _default: 9999
};

const localDefines = require('../../config/defines');

localPorts = localDefines.localPorts;

export const getSubAppPath = (subAppName: string): string => `${subAppPath}${subAppName}`;

/**
 * 获取子应用入口
 * @param pathName 子应用独立运行时对应的路径，需和子应用中 nginx 配置保持一致
 */
const getEntry = (pathName: string): string => {
  let childAppEntry = '';
  if (env === 'development') {
    // 必须启动相应子应用
    childAppEntry = `//127.0.0.1:${localPorts[pathName] || localPorts._default}/index.html`;
  } else if (domainName[env]) {
    childAppEntry = `${subAppPath}${pathName}/index.html`;
  }
  return childAppEntry;
};

/**
 * 生成子应用配置
 * @param subAppName 子应用 vue.config.js 中的 configureWebpack.output.library 值，不可重复
 */
const genChildApp = (subAppName: string, subAppInfo: AppMenuItem): RegistrableApp => ({
  name: subAppName,
  entry: getEntry(subAppName),
  container: '#frame',
  activeRule: `${subAppPath}${subAppName}`,
  props: {
    projectInfo: subAppInfo,
    router,
    actions
  }
});

// 通过接口获取到内容之后，进行二次处理，方便之后注册子应用
async function getApps() {
  // 必须等pinia 注入完再使用
  const userStore = useUserStore();
  const systemStore = useSystemStore();
  const apps: RegistrableApp[] = [];

  const sid = process.env.VUE_APP_SID ? parseInt(process.env.VUE_APP_SID, 10) : null;
  try {
    const res = await getUserProjects(sid);
    if (res.code !== 0) return apps;
    const { projects } = res.data;
    systemStore.$patch((state) => {
      state.projectList = projects;
    });

    // actions.setGlobalState({ authResourceList: resources, projectId });
    // userStore.$patch((state) => {
    //   state.authResourceList = resources;
    // });

    // 添加子应用
    systemStore.appMenuList.forEach((item) => {
      apps.push(genChildApp(item.projectTag, item));
    });
    return apps;
  } catch (e) {
    return [];
  }
}

export default getApps;
