module.exports = {
  parentTag: 'qiankun',
  // 域名
  domainName: {
    test: 'test.nr.lingdong.com',
    pre: 'pre.nr.lingdong.com',
    production: 'nr.lingdong.com'
  },
  subAppPath: '/app/',
  // document.title
  title: '零洞数智新零售工作台'
};
