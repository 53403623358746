import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import qs from 'qs';
import { ElMessage as Message } from 'element-plus';
import { env } from './config';

/**
 * 请求正常服务端返回 code 处理
 * 接口需分 upc、相应后端服务 两种服务接口，UPC接口字符串含v1，通过v1 区别
 * @param {*} res
 */
const checkCode = (res: AxiosResponse) => {
  if (res.status === 200) {
    if (res.data && !res.data?.code) {
      return res;
    }
    // 退出接口特殊处理
    if (res.statusText === 'OK') {
      return res;
    }
    return Promise.reject(res);
  }
  return Promise.reject(res);
};

/**
 * 请求异常 status 处理
 * @param {*} res
 */
const checkStatus = (error: { message: string }) => {
  let { message } = error;
  // 401 且不是 登录页面 则跳转
  if (message.includes('401') && window.location.href !== `${window.location.origin}/login`) {
    console.log('401接口进入登录页');
    window.open(`${window.location.origin}/login`, '_self');
  }
  if (message === 'Network Error') {
    message = '后端接口连接异常';
  }
  if (message.includes('timeout')) {
    message = '后端接口请求超时';
  }
  if (message.includes('Request failed with status code')) {
    const code = message.substr(message.length - 3) || 500;
    message = `后端接口${code}异常`;
  }
  Message({
    showClose: true,
    type: 'error',
    message
  });
  return Promise.reject(error);
};

interface IRequestConfig extends AxiosRequestConfig {
  otherConfig?: any;
}

/**
 * 兼容 contentType 类型
 * @param {*} config
 */
const checkContentType = (config: IRequestConfig) => {
  if (
    Object.prototype.toString.call(config.data) !== '[object FormData]' &&
    config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8'
  ) {
    config.data = qs.stringify(config.data);
  }
  if (config.otherConfig) {
    config = { ...config, ...config.otherConfig };
  }
  return config;
};

const instance = axios.create({
  baseURL: `${env.apiBase}`,
  timeout: 3000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
});

// 请求拦截处理
instance.interceptors.request.use(
  (config) => {
    console.log(config);
    // @ts-ignore
    if (config.headers.type === 'upload') {
      config.baseURL = env.fileBase;
    } else if (config.headers.type === 'isTenant') {
      config.params = {
        ...config.params,
        apiTenant: 6
      };
    }
    delete config.headers.type;
    return checkContentType(config);
  },
  (error) => Promise.reject(error)
);

// 响应拦截处理
instance.interceptors.response.use(
  (response) => checkCode(response),
  (error) => checkStatus(error)
);

type HandRes<T> = {
  code: number;
  message: string;
  data: T;
};

function Request<T = unknown>(configParam: AxiosRequestConfig): Promise<HandRes<T>> {
  return new Promise((resolve, reject) => {
    instance
      .request<T, AxiosResponse<HandRes<T>>>(configParam)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export default {
  // 抛出axios 实例方便扩展
  request: instance,
  /**
   * GET 请求
   * @param {*} url
   * @param {*} params
   * @param {*} headers
   */
  get<T>(
    url: string,
    params: object,
    headers = {},
    otherBase: 'apiBase' | 'logoutBase' = 'apiBase'
  ) {
    if (otherBase !== 'apiBase') {
      return Request<T>({
        baseURL: env[otherBase],
        url,
        method: 'GET',
        params,
        headers
      });
    }
    return Request<T>({
      url,
      method: 'GET',
      params,
      headers
    });
  },

  /**
   * POST请求
   * @param {*} url
   * @param {*} data
   * @param {*} headers
   */
  post<T>(url: string, data: object, headers = {}, params = {}) {
    return Request<T>({
      url,
      method: 'POST',
      data,
      headers,
      params
    });
  },

  /**
   * 上传请求
   * fixme: 上传下载和接口应该用不同的instance
   * @param {*} url
   * @param {*} formdata
   * @param {*} headers
   */
  upload(
    url: string,
    formdata: object,
    headers = {
      'Content-Type': 'multipart/form-data'
    }
  ) {
    return instance({
      url: env.apiBase + url,
      method: 'POST',
      headers,
      data: formdata
    });
  },

  /**
   * 下载请求
   * fixme: 上传下载和接口应该用不同的instance
   * @param {*} url
   * @param {*} data
   * @param {*} headers
   * @param {*} method
   */
  download(url: string, data: object, headers?: any, method?: Method) {
    return instance({
      url,
      method,
      responseType: 'blob',
      transformResponse: [(res) => res],
      data,
      headers
    });
  }
};
