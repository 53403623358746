import api from './request';

// 下载列表
export const postDownloadList = (data: {}) =>
  api.post<Download.DownloadItem[]>('/oss/file/getDownloadList', data, {
    type: 'upload'
  });

// 下载
export const postDownloadUrl = (data: {
  tenantId: number;
  businessId: string;
  businessType: string;
}) =>
  api.post<{ date: string; sign: string; url: string }>('/oss/file/getDownloadUrl', data, {
    type: 'upload'
  });
