import { defineStore } from 'pinia';

interface State {
  hideMenu: boolean;
  hideTop: boolean;
  apps: RegistApp[]; // qiankun 子应用
  isSetApps: boolean;
  projectList: ProjectItem[];
  downloadIconShow: boolean; // 右下角下载icon显示、隐藏状态
}

export const useSystemStore = defineStore('system', {
  state: (): State => ({
    hideMenu: false,
    hideTop: false,
    apps: [],
    // 是否已经设置过apps了，避免无任何权限循环
    isSetApps: false,
    projectList: [],
    downloadIconShow: true
  }),
  getters: {
    appMenuList(): AppMenuItem[] {
      const arr = this.projectList.map((item) => ({
        tenantId: item.tenantId,
        name: item.name,
        projectId: item.id,
        projectUrl: item.site,
        icon: item.icon,
        projectTag: item.site.startsWith('/') ? item.site.substring(1) : item.site // 存名称，从url 中提取
      }));
      console.log('appMenuList:', arr);
      return arr;
    }
  },
  actions: {
    updateHideMenu(newState: boolean) {
      this.hideMenu = newState;
    },
    updateHideTop(newState: boolean) {
      this.hideTop = newState;
    },
    updateApps(apps: RegistApp[]) {
      this.apps = apps;
      this.isSetApps = true;
    }
  }
});
