import api from './request';
import { userProjects } from './mock';

// 查询账号信息
export const loginBUserDetail = () =>
  api.post<any>('user-admin/loginBUserDetail', {}, {}, { apiTenant: 6 });

// 获取用户零售中台有权限的子项目
export const getUserProjects = (sid: number | null) =>
  api.post<{ projects: ProjectItem[] }>(
    '/retail/access/control/getUserProjects',
    { sid },
    { type: 'isTenant' }
  );
// userProjects;
