const localProxyConfig = getAPIProxy();

function getAPIProxy(app = '') {
  let res = {};
  switch (app) {
    case 'test':
      res = {
        // '/api': {
        //   target: 'http://test.com',
        //   changeOrigin: true
        // }
      };
      break;
    default:
      break;
  }

  return res;
}

const localPorts = {
  'sub-app-demo': 10203,
  'commodity-management': 10204,
  'stock-management': 10205,
  'store-management': 10206,
  'promotion-management': 10207,
  'order-management': 10209,
  'fulfill-management': 10210,
  'authority-management': 10211,
  'finance-management': 10212,
  'task-management': 10213,
  'distribution-management': 10214,
  _default: 9999
};

module.exports = {
  localProxyConfig,
  localPorts
};
