import { registerMicroApps, addGlobalUncaughtErrorHandler, start } from 'qiankun';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { RegistrableApp } from './interfaces';

export function setupQiankun(apps: RegistrableApp[]) {
  registerMicroApps(apps, {
    beforeLoad: () => {
      NProgress.start();
      return Promise.resolve();
    },
    afterMount: () => {
      // 为了可以在devtools 工具好调试 而加的代码
      if ((window as any).__QIANKUN_SUB_APP_VM__ && process.env.NODE_ENV === 'development') {
        (window as any)._vueRoot.$children.push((window as any).__QIANKUN_SUB_APP_VM__);
      }

      NProgress.done();
      return Promise.resolve();
    }
  });

  /**
   * 添加全局的未捕获异常处理器addGlobalUncaughtErrorHandler
   */
  addGlobalUncaughtErrorHandler((event: Event | string) => {
    const { message: msg } = event as any;
    // 加载失败时提示
    if (msg && msg.includes('died in status LOADING_SOURCE_CODE')) {
      // 开发者相关信息不必暴露给客户，客户也无法解决
      console.error('子应用加载失败，请检查应用是否可运行');
    } else if (msg) {
      console.error(msg);
    }
  });
}

export default start;
